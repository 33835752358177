import React, { Component } from "react";
import "./css/ErrorBoundaries.css";
import CommonAPI from "../../api/CommonApi";
import Cookies from "js-cookie";

export default class ErrorBoundaries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        console.log("This");
        return {
            hasError: true,
        };
    }
    componentDidCatch(error, info) {
        const token = Cookies.get("Session");
        this.setState({ hasError: true });
        const message = `${error.toString()} ${info["componentStack"]}`;
        CommonAPI.reportError(token, message);
    }
    render() {
        if (this.state.hasError) {
            return (
                <div className="top-margin">
                    <div className="content-align">
                        <h3>Something went wrong...</h3>
                    </div>
                    <div className="content-align">
                        <p>
                            This issue has been reported to the Volta Team. Please reload the page to continue using
                            Volta Analytics
                        </p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
